.grid-container {
    display: inline-grid;
    grid-template-rows: 15% 60% 23%;
    grid-row-gap: 1%;
    height: 90vh;
    width: 100%;
}

.lp-bg-img {
    left: 0;
    right: 0;
    top: -10vh;
    bottom: 0;
    opacity: 0.2;
    position: absolute;
    margin: auto;
    -webkit-filter: blur(1vh);
    width: auto;
    height: auto;
    max-height: 80vh;
    max-width: 100%;
    z-index: -1;
}

.lp-title {
    color: #E0107B;
    margin: auto;
    font-size: 4.2vh;
    font-family: "JonstonITCStdMedium", "p22-underground";
    font-weight: 600;
    text-align: center;
    height: 0;
}

.info-div {
    margin: auto;
    font-size: 4.2vh;
    font-family: "JonstonITCStdMedium", "p22-underground";
    font-weight: 500;
    line-height: 5vh;
    letter-spacing: -0.01vh;
    word-spacing: 0.00001vh;
    text-align: center;
    height: auto;
}

.steps-container {
    display: inline-grid;
    grid-auto-rows: auto auto auto auto;
    grid-row-gap: 3vh;
    margin: auto;
    text-align: center;
}

.lp-button {
    border-color: black;
    border-width: 4px;
    font-family: "JonstonITCStdBold", "p22-underground";
    font-weight: 600;
    font-size: 2.5vh;
    line-height: 4.5vh;
    width: 100%;

    margin: auto;
    color: white;
    background-color: black;
}

.info-span {
    color: white;
    background-color: black;
    padding: 0.4vh;
}

.step-title {
    margin: auto auto 2vh;
    color: white;
    padding: 0 1vh;
    font-size: 4.2vh;
    font-weight: 500;
    background-color: black;
    text-align: center;

    display: inline-block;
    justify-content: center;
    align-items: center;
    align-content: center;
}

/*.step-text {*/
/*    margin: auto;*/
/*    color: black;*/
/*    font-size: 4.2vh;*/
/*    font-family: "JonstonITCStdMedium";*/
/*    text-align: center;*/
/*    width: 100%;*/
/*}*/

.step-text {
    margin: auto;
    font-family: "JonstonITCStdLight", "p22-underground";
    font-weight: 500;
    font-size: 2.5vh;
    text-align: center;
    color: black;
}

.lp-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: auto;
    height: auto;
}

.lp-shadow-underlay {
    margin: auto;
    background-position: center;
    background-image: url("../images/drop_shadow_clean.png");
    background-size: 100% 100%;
    height: 9vh;
    width: 120%;
    position: absolute;
    transform: translate(0, 25%);
    z-index: -1;
}

/* Styles used to animate entering the help content */
.help-anim-enter {
    opacity: 0;
}

.help-anim-enter-active {
    opacity: 1;
    transition: opacity 1500ms ease-in;
}

.help-anim-exit {
    opacity: 1;
}

.help-anim-exit-active {
    opacity: 0;
    transition: opacity 1500ms ease-in;
}

@media (min-width: 280px) {
    .lp-title {
        font-size: 3.5vh;
    }

    .info-div {
        font-size: 3.5vh;
        line-height: 4vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 3.5vh;*/
    /*    line-height: 2.8vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.2vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 320px) {
    .lp-title {
        font-size: 4vh;
    }

    .info-div {
        font-size: 4vh;
        line-height: 4.5vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 4vh;*/
    /*    line-height: 3.2vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.2vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 375px) {
    .lp-title {
        font-size: 3.7vh;
    }

    .info-div {
        font-size: 3.7vh;
        line-height: 4.1vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 3.7vh;*/
    /*    line-height: 3vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.2vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 400px) {
    .lp-title {
        font-size: 3.9vh;
    }

    .info-div {
        font-size: 3.9vh;
        line-height: 4.4vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 3.9vh;*/
    /*    line-height: 3vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.2vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 500px) {
    .lp-title {
        font-size: 4.2vh;
    }

    .info-div {
        font-size: 4.2vh;
        line-height: 4.8vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 4.2vh;*/
    /*    line-height: 3.8vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 4.2vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 768px) {
    .lp-title {
        font-size: 4.2vh;
    }

    .info-div {
        font-size: 4vh;
        line-height: 4.6vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 4.2vh;*/
    /*    line-height: 3.5vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.8vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    .lp-bg-img {
        margin-top: 25vh;
    }
}

@media (min-width: 1024px) {
    .lp-bg-img {
        top: -15vh;
    }

    .lp-title {
        font-size: 3.8vh;
    }

    .info-div {
        font-size: 3.8vh;
        line-height: 4.5vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }

    /*.step-text {*/
    /*    font-size: 3.2vh;*/
    /*    line-height: 3.4vh;*/
    /*    letter-spacing: -0.01vh;*/
    /*    word-spacing: 0.00001vh;*/
    /*}*/

    .step-title {
        font-size: 3.6vh;
        letter-spacing: -0.01vh;
        word-spacing: 0.00001vh;
    }
}