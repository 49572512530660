.qp-container {
  margin: auto;
  min-height: 100%;
  overflow: hidden;
}

.question-number-circle {
  background: #e0107b;
  border-radius: 50%;
  width: 7.5vh;
  height: 7.5vh;
  line-height: 7.5vh;
  text-align: center;
  font-size: 2.5vh;
  color: white;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3vh 3vh auto auto;
}
.nn-green {
  background: #1a9472;
  border-radius: 50%;
  width: 7.5vh;
  height: 7.5vh;
  line-height: 7.5vh;
  text-align: center;
  font-size: 2.5vh;
  color: white;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3vh 3vh auto auto;
}
.nn-red {
  background: #c72b43;
  border-radius: 50%;
  width: 7.5vh;
  height: 7.5vh;
  line-height: 7.5vh;
  text-align: center;
  font-size: 2.5vh;
  color: white;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3vh 3vh auto auto;
}
.qp-stack {
  margin: 19vh auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.qp-stack-wicked {
  background-color: black;
}
.qp-stack-naughty {
  background-color: white;
}
.qp-answer-button {
  border-width: 0.5vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 2.5vh;
  margin: auto;
  line-height: 4.5vh;
  padding: 0.25vh;
  background-size: 200% 100%;
}

.qp-answer-button-reveal-anim {
  animation-name: reveal;
  animation-duration: 0.25s;
  visibility: hidden;
  animation-fill-mode: forwards;
}

.qp-answer-button-hide {
  opacity: 0;
}

.qp-button-container-hide {
  opacity: 0;
}

.qp-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh auto 4vh;
  position: relative;
  width: 100%;
}

.qp-answer-button-innocent {
  border-color: black;
  color: white;
  background-color: black;
  background-image: linear-gradient(to right, black 50%, white 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  width: 100%;
}
.qp-answer-button-nice {
  border-color: black;
  color: white;
  background-color: black;
  background-image: linear-gradient(to right, black 50%, white 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  width: 100%;
}

.qp-answer-button-innocent-anim {
  animation-name: slide-left-innocent;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-style: solid;
  border-color: #e0107b;
  border-width: 0.5vh;
  color: #e0107b;
}
.qp-answer-button-nice-anim {
  animation-name: slide-left-nice;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-style: solid;
  border-color: #1a9472;
  border-width: 0.5vh;
  color: #1a9472;
}
@keyframes slide-left-innocent {
  to {
    background-position: 100% 0;
    color: black;
    background-color: white;
  }
}
@keyframes slide-left-nice {
  to {
    background-position: 100% 0;
    color: black;
    background-color: white;
  }
}
.qp-answer-button-wicked {
  border-color: black;
  color: black;
  background-color: white;
  background-image: linear-gradient(to right, white 50%, black 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  width: 100%;
}

.qp-answer-button-innocent-clicked {
  border-color: #e0107b;
  color: #e0107b;
  background: black;
  width: 100%;
}

.qp-answer-button-wicked-anim {
  animation-name: slide-left-wicked;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-style: solid;
  border-color: #e0107b;
  border-width: 0.5vh;
  color: #e0107b;
}

@keyframes slide-left-wicked {
  to {
    background-position: 100% 0;
    color: white;
    background-color: black;
  }
}

.qp-answer-button-wicked-clicked {
  border-color: #e0107b;
  color: #e0107b;
  background: white;
  width: 100%;
}

.answer-button-innocent:active,
.answer-button-innocent:focus {
  border-width: 4px;
  border-color: #e0107b;
  color: #e0107b;
}

.answer-button-wicked:active,
.answer-button-wicked:focus {
  border-width: 4px;
  border-color: #e0107b;
  color: #e0107b;
}

.qp-title {
  text-align: center;
  margin: 8vh auto auto;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 5vh;
}

.qp-title-innocent {
  color: black;
}

.qp-title-wicked {
  color: white;
}

.qp-question {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #e0107b;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  animation-name: fadein-q;
  animation-duration: 2s;
  animation-direction: normal;
  left: 0;
  top: 0;
}

.qp-ques-cont {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  height: 20vh;
}

@keyframes fadein-q {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.qp-container-wicked {
  background-color: black;
  color: #e0107b;
}

.qp-container-innocent {
  background-color: white;
  color: black;
}

.qp-answer-button-wicked {
  border-color: black;
  color: black;
  background-color: white;
  background-image: linear-gradient(to right, white 50%, black 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  width: 100%;
}

.qp-answer-button-naughty {
  border-color: black;
  color: black;
  background-color: white;
  background-image: linear-gradient(to right, white 50%, black 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  width: 100%;
}

.qp-answer-button-innocent-clicked {
  border-color: #e0107b;
  color: #e0107b;
  background: black;
  width: 100%;
}
.qp-answer-button-nice-clicked {
  border-color: #1a9472;
  color: #1a9472;
  background: black;
  width: 100%;
}

.qp-answer-button-wicked-anim {
  animation-name: slide-left-wicked;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-style: solid;
  border-color: #e0107b;
  border-width: 0.5vh;
  color: #e0107b;
}
.qp-answer-button-naughty-anim {
  animation-name: slide-left-naughty;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-style: solid;
  border-color: #c72b43;
  border-width: 0.5vh;
  color: #c72b43;
}

@keyframes slide-left-wicked {
  to {
    background-position: 100% 0;
    color: white;
    background-color: black;
  }
}
@keyframes slide-left-naughty {
  to {
    background-position: 100% 0;
    color: white;
    background-color: black;
  }
}

.qp-answer-button-wicked-clicked {
  border-color: #e0107b;
  color: #e0107b;
  background: white;
  width: 100%;
}
.qp-answer-button-naughty-clicked {
  border-color: #c72b43;
  color: #c72b43;
  background: white;
  width: 100%;
}

.answer-button-innocent:active,
.answer-button-innocent:focus {
  border-width: 4px;
  border-color: #e0107b;
  color: #e0107b;
}

.answer-button-wicked:active,
.answer-button-wicked:focus {
  border-width: 4px;
  border-color: #e0107b;
  color: #e0107b;
}

.answer-button-nice:active,
.answer-button-nice:focus {
  border-width: 4px;
  border-color: #1a9472;
  color: #1a9472;
}

.answer-button-naughty:active,
.answer-button-naughty:focus {
  border-width: 4px;
  border-color: #c72b43;
  color: #c72b43;
}

.qp-title {
  text-align: center;
  margin: 8vh auto auto;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 6vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.qp-title span {
  text-align: center;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 6vh;
  overflow: hidden;
}
.qp-logo-nice {
  color: black;
}
.qp-logo-naughty {
  color: white;
}
.xms-who-img {
  width: 50%;
  height: 100%;
}
.qp-title-innocent {
  color: black;
}

.qp-title-wicked {
  color: white;
}
.qp-title-naughty {
  color: white;
  width: 20%;
  height: 100%;
  margin-top: 50px;
}
.qp-title-nice {
  color: black;
  width: 20%;
  height: 100%;
  margin-top: 50px;
}
.qp-question {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #e0107b;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  animation-name: fadein-q;
  animation-duration: 2s;
  animation-direction: normal;
  left: 0;
  top: 0;
}

.qp-ques-cont {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  height: 20vh;
}
.qp-black {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #000;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  animation-name: fadein-q;
  animation-duration: 2s;
  animation-direction: normal;
  left: 0;
  top: 0;
}
.qp-white {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  animation-name: fadein-q;
  animation-duration: 2s;
  animation-direction: normal;
  left: 0;
  top: 0;
}

@keyframes fadein-q {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .qp-container-wicked {
  background-color: black;
  color: #e0107b;
}

.qp-container-innocent {
  background-color: white;
  color: black;
} */

.qp-load-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 50%;
  position: relative;
}

.qp-load-inner-container-white {
  background-color: white;
  float: left;
}

.qp-load-inner-container-black {
  background-color: black;
  float: right;
}
.qp-load-inner-container-black-anim {
  background-color: black;
  animation-name: slide-right;
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.qp-load-inner-container-white-anim {
  background-color: black;
  animation-name: slide-left;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

#lod-timer-div {
  margin: auto;
  height: 12vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  text-align: center;
}
#lod-timer-nn-div {
  margin: auto;
  height: 12vh;
  left: 8px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  text-align: center;
}
@keyframes slide-right {
  from {
    background-color: black;
  }
  to {
    background-color: black;
    width: 0;
  }
  0% {
    left: 0;
  }
  100% {
    left: 50%;
  }
}

@keyframes slide-left {
  from {
    background-color: black;
    width: 100%;
  }
  to {
    background-color: black;
  }
  0% {
    left: 0;
  }
  100% {
    left: 50%;
  }
}

@keyframes reveal {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

.qp-answer-button-bubble-up-anim {
  animation-name: bubble-up;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes bubble-up {
  to {
    /*margin-top: 0;*/
  }
}

.qp-qseg-innocent {
  background-color: white;
  height: auto;
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.qp-qseg-wicked {
  background-color: black;
  height: auto;
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.qp-masked-logo {
  mask-image: url("../images/ib_logo_black.png");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto;
  -webkit-mask-image: url("../images/ib_logo_black.png");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
}
.qp-masked-Xmas-logo {
  mask-image: url("../images/leaf_black.png");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto;
  -webkit-mask-image: url("../images/leaf_black.png");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
}

.qp-mask-container {
  display: flex;
  justify-content: center;
  margin: 6vh auto auto;
  height: 12vh;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  position: absolute;
}

.qp-mask-container div {
  width: 100%;
  height: 100%;
}

.qp-masked-inner-container-left {
  background-color: black;
  position: absolute;
  left: 0;
}

.qp-masked-inner-container-right {
  background-color: white;
  position: absolute;
  left: 50%;
}

.qp-masked-inner-container-left-anim {
  animation-name: turn-bg-color-white;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes turn-bg-color-white {
  from {
    background-color: white;
  }
  to {
    background-color: white;
  }
}

.qp-masked-inner-container-right-anim {
  animation-name: turn-bg-color-black;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes turn-bg-color-black {
  from {
    background-color: black;
  }
  to {
    background-color: black;
  }
}
@media (width: 375px) and (height: 812px) {
  .qp-question {
    font-size: 3.2vh;
  }
}
@media (min-width: 375px) {
  .qp-title-nice,
  .qp-title-naughty {
    width: 20%;
  }
  /* #lod-timer-div {
    top: -18px;
  } */
}
