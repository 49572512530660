.shadow-underlay {
    margin: auto;
    background-position: center;
    background-image: url("../images/drop_shadow_clean.png");
    background-size: 100% 100%;
    height: 9vh;
    width: 120%;
    position: absolute;
    transform: translate(0, 25%);
    z-index: -1;
}

.shadow-underlay-big {
    margin: auto;
    background-position: center;
    background-image: url("../images/drop_shadow_clean.png");
    background-size: 100% 100%;
    height: 12vh;
    width: 120%;
    position: absolute;
    transform: translate(0, 25%);
    z-index: -1;
}

.shadow-underlay-black {
    margin: auto;
    background-position: center;
    background-image: url("../images/drop_shadow_clean.png");
    background-size: 100% 100%;
    height: 9vh;
    width: 120%;
    position: absolute;
    transform: translate(0, 25%);
    z-index: -1;
}