body {
  margin: 0;
  font-family: p22-underground, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "JonstonITCStdBold";
  src: local("JonstonITCStdBold"), url("../fonts/JohnstonITCStd/JohnstonITCStd-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JonstonITCStdBoldItalic";
  src: local("JonstonITCStdBoldItalic"), url("../fonts/JohnstonITCStd/JohnstonITCStd-BoldItalic.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JonstonITCStdLight";
  src: local("JonstonITCStdLight"), url("../fonts/JohnstonITCStd/JohnstonITCStd-Light.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JonstonITCStdLightItalic";
  src: local("JonstonITCStdLightItalic"), url("../fonts/JohnstonITCStd/JohnstonITCStd-LightItalic.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JonstonITCStdMedium";
  src: local("JonstonITCStdMedium"), url("../fonts/JohnstonITCStd/JohnstonITCStd-Medium.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JonstonITCStdMediumItalic";
  src: local("JonstonITCStdMediumItalic"), url("../fonts/JohnstonITCStd/JohnstonITCStd-MediumItalic.otf") format("truetype");
  font-weight: bold;
}