.sp-container {
  display: inline-grid;
  grid-auto-rows: 10% 32% 50%;
  grid-row-gap: 0;
  height: 90vh;
  margin: auto;
  width: 100%;
}

.logo-img {
  width: 20vh;
  margin: 3vh auto auto;
}

.sp-text-label {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 4.5vh;
  line-height: 3.8vh;
  margin: 8vh auto auto;
}
.sp-text-tile-label {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 4.5vh;
  line-height: 3.8vh;
  margin: 5vh auto 3vh auto;
}

.sp-input-container {
  margin: 0 auto auto;
  width: 100%;
}

.sp-val-cont {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  margin: 2vh auto auto;
  font-size: 2vh;
  color: #e0107b;
  line-height: 1.8vh;
}

.sp-text-box-container {
  margin: 2vh auto auto;
}

.sp-text-box {
  border-color: black;
  border-width: 4px;
  width: 100%;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  font-size: 5vh;
}

.sp-text-box-validated {
  border-color: #e0107b;
  border-width: 4px;
  width: 100%;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  font-size: 5vh;
}

.sp-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto;
  /*margin-top: 30.8vh;*/
}

.sp-button {
  color: white;
  background-color: black;
  border-color: black;
  border-width: 4px;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 2.5vh;
  line-height: 4.5vh;
  width: 100%;
  margin: auto;
  position: absolute;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
}
.tile {
  height: 250px;
  position: relative;
  width: 48%;
  transition: background-color border 0.1s ease-in-out;
  cursor: pointer;
  padding: 5px;
  box-shadow: 5px 2px 14px rgb(178 176 176);
}

.tilesTitle {
  color: #000;
  position: absolute;
  bottom: 9px;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  width: 100%;
  font-size: 3vh;
  line-height: 2.7vh;
  /* letter-spacing: 1px; */
  text-align: center;
}

.tile img {
  width: 100%;
  height: 70%;
  object-fit: contain;
  overflow: hidden;
}

.active {
  border: 5px solid #000;
}
.selectedShadow {
  margin: auto;
  background-position: center;
  background-image: url("../images/drop_shadow_clean.png");
  background-size: 100% 100%;
  height: 9vh;
  width: 120%;
  position: absolute;
  transform: translate(0, 25%);
  z-index: -1;
}
@media screen and (max-width: 425px) {
  .sp-container {
    grid-auto-rows: 10% 32% 46%;
  }
  .tile {
    height: 210px;
  }
  .tile img {
    height: 80%;
  }
  .tile img.wickImg {
    height: 65%;
    margin-top: 14px;
  }
  .wickedTitle,
  .innocentTitle {
    font-size: 6vw;
    line-height: 6vw;
  }
  .tilesTitle {
    font-size: 2.6vh;
    line-height: 2.5vh;
  }
}
@media (min-width: 280px) {
  .logo-img {
    margin-top: 12vh;
  }
}

@media (min-width: 320px) {
  .logo-img {
    margin-top: 7vh;
  }
}

@media (min-width: 375px) {
  .logo-img {
    margin-top: 10vh;
  }
}

@media (min-width: 411px) {
  .logo-img {
    margin-top: 10vh;
  }
}

@media (min-width: 540px) {
  .logo-img {
    margin-top: 9vh;
  }
}

@media (min-width: 768px) {
  .logo-img {
    margin-top: 9vh;
  }
}
/* .selectedDropShadow {
  position: absolute;
  bottom: -77px;
  right: -66px;
}
.selectedDropShadow img {
  width: 100%;
  height: 100%;
} */
.selectedDropShadow {
  background-position: center;
  background-image: url("../images/drop_shadow_clean.png");
  background-size: 100% 100%;

  position: absolute;
  z-index: -1;
  bottom: -41px;
}
.leftMargin {
  height: 6vh;
  width: 55%;
  left: -18px;
}
.rightMargin {
  height: 6vh;
  width: 55%;
  right: -14px;
}
@media screen and (max-height: 746px) {
  .sp-container {
    grid-auto-rows: 10% 32% 56%;
  }
}
/* @media screen and (max-height: 896px) {
  .sp-container {
    grid-auto-rows: 10% 30% 44% !important;
  }
}

@media screen and (max-height: 740px) {
  .sp-container {
    grid-auto-rows: 10% 30% 49% !important;
  }
} */
/* @media screen and (max-height: 667px) {
  .sp-container {
    grid-auto-rows: 10% 30% 53% !important;
  }
} */
@media screen and (max-height: 720px) {
  .sp-container {
    grid-auto-rows: 10% 32% 50%;
  }
}
