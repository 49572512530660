.app-button {
  width: 65%;
  height: 60px;
  font-size: calc(10px + 2vmin);
  margin: auto;
}

.timer {
  margin-top: 50px;
}

.top-buffer { margin-top:10px; }