.wtp-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wtp-title-text {
  text-align: center;
  margin: 6vh auto auto;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 5vh;
}

.form {
  margin: auto;
}

.wtp-text-label {
  font-family: "JonstonITCStdLight", "p22-underground";
  font-weight: 500;
  font-size: 2.5vh;
  text-align: center;
  margin: 5vh auto auto;
}

.wtp-wait-inst-label {
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  font-size: 2.5vh;
  text-align: center;
  margin: 3vh auto 6vh;
}

.wtp-text-box {
  border-color: black;
  border-width: 4px;
  height: calc(20% + 2vmin);
  font-size: calc(50px + 2vmin);
  width: 100%;
}

.wtp-button {
  border-color: black;
  border-width: 3px;
  font-size: 2.5vh;
  line-height: 4.5vh;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  margin: auto;
}

.wp-share-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto;
}

.wtp-share-button {
  padding-top: 1.5vh;
  padding-bottom: 1vh;
  color: #e0107b;
  background-color: white;
  /*margin-top: 1vh;*/
  background-image: url(../images/share_icon.png);
  background-size: 3vh;
  background-position: right top;
  background-position-x: 98%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  font-size: 2.5vh;
  text-align: center;
  line-height: 3vh;
  width: 100%;
  margin: auto;
}

.wtp-room-no-div {
  color: #e0107b;
  margin: 4vh auto auto;
  font-size: 5vh;
  font-family: "JonstonITCStdLight", "p22-underground";
}

.wp-room-no-span {
  color: #e0107b;
  font-size: 5vh;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
}

.wtp-begin-button {
  color: white;
  background-color: #e0107b;
  width: 100%;
  position: absolute;
  font-size: 4vh;
  line-height: 8vh;
  margin-bottom: 4vh;
}

.wtp-button-disabled {
  background-color: grey;
}

.wtp-begin-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 5vh auto 8vh;
}

.wtp-list-group {
  margin: 2vh auto;
}
.wp-text-tile-label {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 4.5vh;
  line-height: 3.8vh;
  margin: 9vh auto 1vh auto;
}
.wtp-list-item {
  margin: 1vh auto;
  border-style: dotted;
  border-width: 0.24vh;
  /*padding: 0.8vh;*/
  line-height: 6vh;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 2.5vh;
  background-color: black;
  color: white;
  text-align: center;
}

.wtp-val-cont {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  margin: 4vh auto auto;
  font-size: 2vh;
  color: #e0107b;
  /*width: 80%;*/
}
.wicked-selected {
  margin: 1vh auto;
  height: 320px;
  position: relative;
  box-shadow: 3px 2px 14px rgb(232 227 227);
}
.drop-shadow {
  width: 105%;
  height: 18%;
  position: absolute;
  bottom: -58px;
  z-index: -1;
  left: -25px;
}

.left-shadowMan img {
  width: 63%;
  position: absolute;
  height: 320px;
  object-fit: cover;
}
.cards-title {
  font-family: "JonstonITCStdBold", "p22-underground";
  font-style: normal;
  font-weight: 700;
  position: absolute;
  left: 310px;
  top: 110px;
  font-size: 6.5vh;
  line-height: 5.8vh;
  margin: 2vh auto 3vh auto;
  color: #000;
}

.left-wicked-shadowMan img {
  width: 40%;
  position: absolute;
  height: 320px;
  object-fit: cover;
}
.naughty-selected {
  margin: 1vh auto;
  height: 320px;
  position: relative;
  box-shadow: 3px 2px 14px rgb(232 227 227);
}
@media screen and (max-width: 425px) {
  .wicked-selected,
  .naughty-selected {
    height: 150px;
  }
  .left-wicked-shadowMan img {
    width: 42%;
    height: auto;
  }
  .cards-title {
    left: 145px;
    top: 51px;
    font-size: 3.5vh;
    line-height: 3.2vh;
  }
  .left-shadowMan img {
    width: 66%;
    height: 100%;
  }
  .drop-shadow {
    bottom: -28px;
  }
}
@media screen and (max-width: 426px) and (min-width: 925px) {
  .cards-title {
    font-size: 6.5vh;
    line-height: 5.8vh;
  }
}
@media screen and (max-width: 926px) and (min-width: 1495px) {
  .left-shadowMan img {
    width: 45%;
    position: absolute;
    height: 320px;
    object-fit: cover;
  }
  .cards-title {
    left: 270px;
    top: 110px;
    font-size: 6.5vh;
    line-height: 5.8vh;
  }
}
/* @media screen and (max-height: 844px) {
  .wicked-selected,
  .naughty-selected {
    height: 155px;
  }
} */
/* @media screen and (max-width: 1025px) and (max-height: 695px) {
  .wicked-selected,
  .naughty-selected {
    height: 321px;
  }
} */
