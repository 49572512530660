.rp-container {
  margin: auto;
  min-height: 100%;
  overflow: hidden;
  height: 100vh;
}

.rp-container-wicked {
  background-color: black;
  color: #e0107b;
  text-align: center;
  height: 100vh;
}

.rp-no-answer-para-1 {
  color: black;
  margin: 3vh auto auto;
  font-size: 4vh;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  text-align: center;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

.rp-no-answer-para-2 {
  margin: 4vh auto auto;
  font-size: 4vh;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  text-align: center;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}
.rp-no-answer-para-3 {
  margin: 5vh auto auto;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}
.rp-title {
  text-align: center;
  margin: 8vh auto auto;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 6vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.rp-title span {
  text-align: center;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 6vh;
  line-height: 6vh;
  overflow: hidden;
}
.rp-logo-nice {
  color: black;
}
.rp-logo-naughty {
  color: white;
}
.leftDiv {
  width: 180px;
  height: 60px;
  background-color: aqua;
}
.rightDiv {
  width: 180px;
  height: 60px;
  background-color: aqua;
}
.xms-who-img {
  width: 50%;
  height: 100%;
}
.rp-title-innocent {
  color: black;
}
.rp-title-wicked {
  color: white;
}
.rp-title-nice {
  color: black;
  width: 50%;
  height: 100%;
  margin-top: 50px;
}
.rp-title-naughty {
  color: white;
  width: 50%;
  height: 100%;
  margin-top: 50px;
}

.rp-question {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #e0107b;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  left: 0;
  top: 0;
}
.rp-black {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #000;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  left: 0;
  top: 0;
}
.rp-white {
  font-size: 3.5vh;
  margin: auto;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 4.6vh;
  position: relative;
  left: 0;
  top: 0;
}
.rp-ques-cont {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  height: 20vh;
}

#rp-timer-div {
  margin: auto;
  height: 12vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: -8px;
  z-index: 1;
  position: absolute;
}

.rp-answer {
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-size: 2.5vh;
  text-align: center;
  border: 4px solid #e0107b;
  padding: 0.25vh;
  line-height: 4.5vh;
  position: relative;
  margin: 20vh auto auto;
  background-size: 200% 100%;
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
}

.rp-answer-innocent {
  color: black;
  background-image: linear-gradient(to right, #e0107b 50%, white 50%);
}

.rp-answer-wicked {
  color: white;
  background-image: linear-gradient(to right, #e0107b 50%, black 50%);
}

.rp-answer-for-nice {
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-size: 2.5vh;
  text-align: center;
  border: 4px solid #1a9472;
  padding: 0.25vh;
  line-height: 4.5vh;
  position: relative;
  margin: 20vh auto auto;
  background-size: 200% 100%;
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
}

.rp-answer-for-naughty {
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-size: 2.5vh;
  text-align: center;
  border: 4px solid #c72b43;
  padding: 0.25vh;
  line-height: 4.5vh;
  position: relative;
  margin: 20vh auto auto;
  background-size: 200% 100%;
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
}

.rp-answer-nice {
  color: black;
  background-image: linear-gradient(to right, #1a9472 50%, white 50%);
}
.rp-answer-naughty {
  color: white;
  background-image: linear-gradient(to right, #c72b43 50%, black 50%);
}

.no-one-answer-div {
  margin: 10vh auto auto;
  font-size: 4vh;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
}

.no-one-answer-info-div {
  margin: 10vh auto auto;
  font-size: 3.5vh;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
}

.rp-no-answer-container {
  margin: 10vh auto 25vh;
  min-height: 100%;
  overflow: hidden;
  height: 100vh;
}

.rp-qseg-innocent {
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
}

.rp-qseg-wicked {
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* .rp-animated-answer {
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 2.5vh;
  text-align: center;
  border: 4px solid #e0107b;
  padding: 0.25vh;
  margin: 33vh auto auto;
  position: absolute;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #e0107b 50%, white 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  animation-name: rp-slide-out;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
} */

.rp-answer-count-label {
  margin: 1.5vh auto auto;
  color: grey;
  font-size: 2vh;
  text-align: center;
  position: relative;
}

@media (width: 375px) and (height: 812px) {
  .qp-question {
    font-size: 3.2vh;
  }
}

/* Answer percentage animations - innocent */

.rp-sliding-anim-innocent {
  animation-name: rp-slide-out-innocent;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-1 {
  animation-name: rp-slide-out-innocent-1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-2 {
  animation-name: rp-slide-out-innocent-2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-3 {
  animation-name: rp-slide-out-innocent-3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-4 {
  animation-name: rp-slide-out-innocent-4;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-5 {
  animation-name: rp-slide-out-innocent-5;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-6 {
  animation-name: rp-slide-out-innocent-6;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-7 {
  animation-name: rp-slide-out-innocent-7;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-8 {
  animation-name: rp-slide-out-innocent-8;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-innocent-9 {
  animation-name: rp-slide-out-innocent-9;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

/* Answer percentage animations - wicked */

.rp-sliding-anim-wicked {
  animation-name: rp-slide-out-wicked;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-1 {
  animation-name: rp-slide-out-wicked-1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-2 {
  animation-name: rp-slide-out-wicked-2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-3 {
  animation-name: rp-slide-out-wicked-3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-4 {
  animation-name: rp-slide-out-wicked-4;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-5 {
  animation-name: rp-slide-out-wicked-5;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-6 {
  animation-name: rp-slide-out-wicked-6;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-7 {
  animation-name: rp-slide-out-wicked-7;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-8 {
  animation-name: rp-slide-out-wicked-8;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-wicked-9 {
  animation-name: rp-slide-out-wicked-9;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes rp-slide-out-innocent {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 0 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 0 0;
    color: white;
    background-color: #e0107b;
  }
}

/* Answer percentage animation keyframes - wicked */

@keyframes rp-slide-out-wicked-1 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 10% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-2 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 20% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-3 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 30% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-4 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 40% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-5 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 50% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-6 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 60% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-7 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 70% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-8 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 80% 0;
    color: white;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-wicked-9 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: black;
  }
  100% {
    background-position: 90% 0;
    color: white;
    background-color: #e0107b;
  }
}

/* Answer percentage animation keyframes - innocent */

@keyframes rp-slide-out-innocent-1 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 10% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-2 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 20% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-3 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 30% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-4 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 40% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-5 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 50% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-6 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 60% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-7 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 70% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-8 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 80% 0;
    color: black;
    background-color: #e0107b;
  }
}

@keyframes rp-slide-out-innocent-9 {
  0% {
    background-position: 100% 0;
    color: #e0107b;
    background-color: white;
  }
  100% {
    background-position: 90% 0;
    color: black;
    background-color: #e0107b;
  }
}

/* Answer percentage animations - nice */

.rp-sliding-anim-nice {
  animation-name: rp-slide-out-nice;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-1 {
  animation-name: rp-slide-out-nice-1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-2 {
  animation-name: rp-slide-out-nice-2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-3 {
  animation-name: rp-slide-out-nice-3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-4 {
  animation-name: rp-slide-out-nice-4;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-5 {
  animation-name: rp-slide-out-nice-5;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-6 {
  animation-name: rp-slide-out-nice-6;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-7 {
  animation-name: rp-slide-out-nice-7;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-8 {
  animation-name: rp-slide-out-nice-8;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-nice-9 {
  animation-name: rp-slide-out-nice-9;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

/* Answer percentage animations - naughty */

.rp-sliding-anim-naughty {
  animation-name: rp-slide-out-naughty;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-1 {
  animation-name: rp-slide-out-naughty-1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-2 {
  animation-name: rp-slide-out-naughty-2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-3 {
  animation-name: rp-slide-out-naughty-3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-4 {
  animation-name: rp-slide-out-naughty-4;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-5 {
  animation-name: rp-slide-out-naughty-5;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-6 {
  animation-name: rp-slide-out-naughty-6;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-7 {
  animation-name: rp-slide-out-naughty-7;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-8 {
  animation-name: rp-slide-out-naughty-8;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.rp-sliding-anim-naughty-9 {
  animation-name: rp-slide-out-naughty-9;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes rp-slide-out-nice {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 0 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-naughty {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: white;
  }
  100% {
    background-position: 0 0;
    color: white;
    background-color: #c72b43;
  }
}

/* Answer percentage animation keyframes - naughty */

@keyframes rp-slide-out-naughty-1 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 10% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-2 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 20% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-3 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 30% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-4 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 40% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-5 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 50% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-6 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 60% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-7 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 70% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-8 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 80% 0;
    color: white;
    background-color: #c72b43;
  }
}

@keyframes rp-slide-out-naughty-9 {
  0% {
    background-position: 100% 0;
    color: #c72b43;
    background-color: black;
  }
  100% {
    background-position: 90% 0;
    color: white;
    background-color: #c72b43;
  }
}
.bottom-img {
  width: 75%;
  height: 100%;
}
/* Answer percentage animation keyframes - nice */

@keyframes rp-slide-out-nice-1 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 10% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-2 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 20% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-3 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 30% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-4 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 40% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-5 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 50% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-6 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 60% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-7 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 70% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-8 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 80% 0;
    color: black;
    background-color: #1a9472;
  }
}

@keyframes rp-slide-out-nice-9 {
  0% {
    background-position: 100% 0;
    color: #1a9472;
    background-color: white;
  }
  100% {
    background-position: 90% 0;
    color: black;
    background-color: #1a9472;
  }
}
@media (min-width: 375px) {
  .rp-title-naughty,
  .rp-title-nice {
    width: 60%;
  }
  #rp-timer-div {
    top: -18px;
  }
}
.black-left-cls {
  width: 20%;
  height: 100%;
  margin-top: 50px;
}
