.ou-preload-div {
  color: black;
  margin: 5vh auto auto;
  font-size: 4vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  line-height: 6vh;
  text-align: center;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}
.red-shawow-img {
  margin: 0px auto auto auto;
  width: 110px;
  height: 228px;
  z-index: 1;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.outro-preload-info-div {
  margin: 5vh auto auto;
  font-size: 4vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  line-height: 6vh;
  text-align: center;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

.ou-container {
  /* position: absolute; */
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.ou-xmas-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.ou-preload-container {
  margin-top: 8vh;
  margin-bottom: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ou-inner-container {
  height: 50%;
  width: 100%;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -2;
}
.ounice-container {
  position: relative;
  background-color: white;
  color: black;
  /* padding-top: 250px; */
}
.onice-inner-container {
  height: 61vh;
  width: 100%;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* z-index: -2; */
}
.onaughty-inner-container {
  height: 60%;
  width: 100%;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* z-index: -1; */
  padding-bottom: 5vh;
}
.oui-container {
  position: relative;
  background-color: white;
  color: black;
  background-image: url("../images/endframe_white.png");
  background-size: 100% 100%;
}

.ouw-container-before {
  background-color: black;
  color: white;
  background-size: 100% 100%;
  background-image: url("../images/endframe_black.png");
}
.ouNaughty-container-before {
  background-color: black;
  color: white;
}

.ouw-container-before::after {
  background-color: black;
  color: white;
  background-image: url("../images/endframe_black.png");
}

@keyframes paint-up {
  from {
    background-position: 0 0;
    color: black;
    background-color: white;
  }
  to {
    background-position: 0 100%;
    color: white;
    background-color: black;
    background-image: url("../images/endframe_black.png");
  }
}

.oui-result-container {
  width: 100%;
  text-align: center;
  font-size: 7vh;
}

.oui-result-desc-container {
  width: 100%;
  text-align: center;
  font-size: 4vh;
}

.ouw-result-container {
  width: 100%;
  text-align: center;
  font-size: 7vh;
}
.ounice-result-container {
  width: 100%;
  text-align: center;
  font-size: 7vh;
  line-height: normal;
}
.ounaughty-result-container {
  width: 100%;
  text-align: center;
  font-size: 7vh;
  margin-top: 26vh;
  line-height: normal;
  /* height: 100px; */
}
.rp-text-tile-label {
  text-align: center;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-size: 4.5vh;
  line-height: 3.8vh;
  margin: 17vh auto 3vh auto;
}
.ouw-result-desc-container {
  width: 100%;
  text-align: center;
  font-size: 4vh;
}

.ou-play-button {
  font-size: 2.5vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  background-color: white;
  border-width: 4.5px;
  border-color: black;
  color: #e0107b;
  padding: 0.5vh;
  padding-top: 1vh;
  width: 100%;
  text-align: center;
  line-height: inherit;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.ouxmas-play-button {
  font-size: 2.5vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  background-color: white;
  /* border-width: 4.5px; */
  border-color: black;
  color: #e0107b;
  padding: 2vh;
  padding-top: 2.5vh;
  width: 100%;
  text-align: center;
  line-height: inherit;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.xmas-link {
  /* text-align: center;
  width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 3vh auto;
}
.xmas-sendLink {
  text-align: center;
  width: 100%;
}
.find-out-link {
  width: 100%;
  text-align: center;
  color: white;
  margin: 2vh auto;
  font-size: 2vh;
}
a.find-out-link:visited,
a.find-out-link:hover {
  color: white;
}
.find-out-logo {
  width: 100%;
  height: 100%;
}
.know-more-btn {
  font-size: 2.4vh;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  background-color: white;
  border-color: black;
  color: #e0107b !important;
  padding: 2vh;
  padding-top: 2.5vh;
  /* width: 100%; */
  text-align: center;
  line-height: inherit;
  /* text-decoration: none; */
  cursor: pointer;
}
.xmas-sendLink a {
  position: relative !important;
  bottom: 0px !important;
}
.red-shadowman-center {
  position: absolute;
  top: 47vh;
}
.ou-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: absolute;
}
.ouxmas-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh auto;
}
.ou-link {
  margin: auto;
  text-align: center;
  font-size: 2vh;
  color: white;
  position: absolute;
  bottom: 5vh;
}

.info-naughty {
  background-color: #c72b43;
  color: #000;
  padding: 5px;
}

.info-nice {
  background-color: #1a9472;
  color: #fff;
  padding: 5px;
}

.outo-bottom-div {
  margin: 2vh auto;
  width: 100%;
  text-align: center;
}
.bottom-img {
  width: 50%;
  height: 100%;
}
.green-leaf-img {
  width: 50%;
  height: 100%;
}
/* Fade in effect */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*!* Height filling in effect *!*/
@keyframes flyin {
  to {
    /*filter: blur(0);*/
    transform: scale(1);
    opacity: 1;
    background-color: black;
    color: white;
    background-image: url("../images/endframe_black.png");
    background-size: 100% 100%;
  }
}

@keyframes flyin-white {
  to {
    transform: scale(1);
    opacity: 1;
    background-color: black;
    color: white;
    background-image: url("../images/endframe_black.png");
    background-size: 100% 100%;
  }
}

.line-cont {
  position: absolute;
  border: none;
  border-top: 1px dotted black;
  height: 0.5vh;
  animation-name: draw-line;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes draw-line {
  0% {
    top: 50%;
    width: 0;
  }

  100% {
    top: 50%;
    width: 100%;
  }
}
.ou-tile {
  height: 250px;
  position: relative;
  width: 48%;
  transition: background-color border 0.1s ease-in-out;
  padding: 10px;
  background-color: white;
}

.ou-tilesTitle {
  color: #000;
  position: absolute;
  bottom: 0px;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  width: 90%;
  font-size: 3vh;
  line-height: 2.5vh;
  letter-spacing: 1px;
  text-align: center;
}

.ou-tile img {
  width: 100%;
  height: 70%;
  object-fit: contain;
  overflow: hidden;
}

@media screen and (max-width: 425px) {
  .ou-tile {
    height: 210px;
  }
  .ou-tile img {
    height: 70%;
  }
  .ou-tile img.wickImg {
    height: 70%;
    /* margin-top: 14px; */
  }
  .wickedTitle,
  .innocentTitle {
    font-size: 6vw;
    line-height: 6vw;
  }
  .red-shawow-img {
    margin: -20px auto auto auto;
    z-index: 1;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }
}
