.wp-container {
    display: inline-grid;
    grid-row-gap: 0;
    grid-template-rows: 10% 60% 20% 10%;
    height: 90vh;
    width: 100%;
}

.logo-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    margin: auto;
}

.logo-img {
    padding-top: 5vh;
    margin: auto;
}

.title-img {
    margin: auto;
    background-position: center;
    background-image: url("../images/title.png");
    background-size: 100% 100%;
    z-index: -1;
    width: auto;
    height: auto;
    max-width: 75%;
}

.wp-button {
    color: white;
    background-color: black;
    border-color: black;
    border-width: 4px;
    font-family: "JonstonITCStdBold", "p22-underground";
    font-weight: 600;
    font-weight: bold;
    font-size: 2.5vh;
    line-height: 4.5vh;
    margin: auto;
    width: 100%;
}

.subscription-button {
    color: #E0107B;
    background-color: white;
    border-color: #E0107B;
}

.wp-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto auto 3vh;
    position: relative;
    width: 100%;
}

.wp-stack {
    display: inline-grid;
    grid-template-rows: auto auto;
    grid-row-gap: 0.5vh;
    margin: auto;
}

.wp-tnc-link {
    margin: auto;
    text-align: center;
    font-size: 2vh;
    color: black;
    padding-bottom: 5vh;
}

@media (min-width: 540px) {
    .title-img {
        max-width: 60%;
    }
}

@media (min-width: 768px) {
    .title-img {
        max-width: 60%;
    }
}

@media (min-width: 1024px) {
    .title-img {
        max-width: 25%;
    }
}

@media (width: 1024px) and (height: 1366px) {
    .title-img {
        max-width: 60%;
    }
}