.jp-container {
  display: inline-grid;
  grid-auto-rows: 10% 30% 33% 27%;
  grid-row-gap: 0;
  height: 90vh;
  margin: auto;
  width: 100%;
}

.logo-img {
  width: 20vh;
  margin: 3vh auto auto;
}

.jp-input-container {
  margin: 0 auto auto;
  width: 100%;
}

.jp-text-label {
  text-align: left;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-weight: bold;
  font-size: 5vh;
  line-height: 4vh;
  margin: 8vh auto auto;
}

.jp-text-box {
  border-color: black;
  border-width: 4px;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  font-size: 6vh;
  width: 100%;
  margin: auto;
}

.jp-val-cont {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  margin: 2vh auto auto;
  font-size: 2vh;
  color: #e0107b;
}

.jp-text-box-validated {
  border-color: #e0107b;
  border-width: 4px;
  width: 100%;
  font-family: "JonstonITCStdMedium", "p22-underground";
  font-weight: 500;
  font-size: 6vh;
}

.jp-input-stack {
  margin: 6vh auto auto;
}

.jp-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 11vh auto auto;
}

.join-button {
  color: white;
  background-color: black;
  border-color: black;
  border-width: 4px;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  font-weight: bold;
  font-size: 2.5vh;
  line-height: 4.5vh;
  margin: auto;
  width: 100%;
}

.jp-text-div {
  text-align: left;
  font-family: "JonstonITCStdLight", "p22-underground";
  font-weight: 300;
  font-size: 3vh;
  margin: 2vh auto 1vh;
  line-height: 2.8vh;
}

@media (min-width: 280px) {
  .logo-img {
    margin-top: 12vh;
  }

  .jp-input-stack {
    margin-top: 4vh;
  }

  .jp-text-label {
    font-size: 4vh;
  }

  .jp-text-div {
    font-size: 2.2vh;
  }
}

@media (min-width: 320px) {
  .logo-img {
    margin-top: 7vh;
  }

  .jp-input-stack {
    margin-top: 6vh;
  }
}

@media (min-width: 375px) {
  .logo-img {
    margin-top: 10vh;
  }
}

@media (min-width: 411px) {
  .logo-img {
    margin-top: 10vh;
  }
}

@media (min-width: 540px) {
  .logo-img {
    margin-top: 9vh;
  }
}

@media (min-width: 768px) {
  .logo-img {
    margin-top: 9vh;
  }
}
.jp-val-cont {
  text-align: left;
  font-weight: bold;
  font-family: "JonstonITCStdBold", "p22-underground";
  font-weight: 600;
  margin: 2vh auto auto;
  font-size: 2vh;
  color: #e0107b;
  line-height: 1.8vh;
}
